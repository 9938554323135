import "../styles/global.css";

import React from "react";
import tw, { css, theme, GlobalStyles as BaseStyles } from "twin.macro";
import { Global } from "@emotion/react";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { PageProps } from "gatsby";
import { HistoryContextProvider } from "../context/HistoryContext";

const customStyles = css({
  body: {
    WebkitTapHighlightColor: theme`colors.blue.100`,
    ...tw`antialiased`,
  },
});

const App: React.FC<
  PageProps<{ children: React.ReactElement }, { navbarTransparent: boolean }>
> = ({ children, pageContext }) => (
  <HistoryContextProvider>
    <>
      <BaseStyles />
      <Global styles={customStyles} />
      <Navbar transparent={pageContext.navbarTransparent} />
      {children}
      <Footer />
    </>
  </HistoryContextProvider>
);

export default App;
