exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-expertise-tsx": () => import("./../../../src/pages/expertise.tsx" /* webpackChunkName: "component---src-pages-expertise-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-journal-tsx": () => import("./../../../src/pages/journal.tsx" /* webpackChunkName: "component---src-pages-journal-tsx" */),
  "component---src-pages-patient-stories-tsx": () => import("./../../../src/pages/patient-stories.tsx" /* webpackChunkName: "component---src-pages-patient-stories-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-publications-tsx": () => import("./../../../src/pages/publications.tsx" /* webpackChunkName: "component---src-pages-publications-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-your-surgeon-tsx": () => import("./../../../src/pages/your-surgeon.tsx" /* webpackChunkName: "component---src-pages-your-surgeon-tsx" */),
  "component---src-templates-blogpost-template-tsx": () => import("./../../../src/templates/BlogpostTemplate.tsx" /* webpackChunkName: "component---src-templates-blogpost-template-tsx" */),
  "component---src-templates-consulting-room-template-tsx": () => import("./../../../src/templates/ConsultingRoomTemplate.tsx" /* webpackChunkName: "component---src-templates-consulting-room-template-tsx" */),
  "component---src-templates-expertise-template-tsx": () => import("./../../../src/templates/ExpertiseTemplate.tsx" /* webpackChunkName: "component---src-templates-expertise-template-tsx" */)
}

