import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "@reach/router";
import qs from "query-string";
import { nanoid } from "nanoid";

const HistoryContext = createContext<{
  handleConversion: (tags: string, component: string) => void;
}>({
  handleConversion: () => {},
});

const useHistoryContext = () => {
  const context = useContext(HistoryContext);
  if (context === undefined) {
    throw new Error("useAppState must be used within a Provider");
  }
  return context;
};

const HistoryContextProvider: React.FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  const location = useLocation();
  const [crumbs, setCrumbs] = useState<string[]>([]);
  const randomId = useMemo(() => nanoid(), []);

  const handleConversion = async (tags: string, component: string) => {
    const initialPageQuery = qs.parse(crumbs[0].split("?")[1]);
    await fetch(
      "https://europe-west2-omnified-optical.cloudfunctions.net/dmt-registerAdConversion",
      {
        method: "POST",
        body: JSON.stringify({
          history: crumbs,
          tags,
          isDevRecord: process.env.NODE_ENV !== "production",
          component,
          initialPageQuery,
          randomId,
          isGoogleAd: !!initialPageQuery.gclid,
        }),
      }
    );
  };

  useEffect(() => {
    setCrumbs((prev) => [...prev, location.href]);
  }, [location]);

  return (
    <HistoryContext.Provider
      value={{
        handleConversion,
      }}
    >
      {children}
    </HistoryContext.Provider>
  );
};

export { HistoryContextProvider, useHistoryContext };
