import React, { useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import tw from "twin.macro";
import { useScroll } from "framer-motion";
import { ClassNames } from "@emotion/react";
import { Transition } from "@headlessui/react";
import NavItem from "./NavItem";

const getHeaderNavs: (expertiseLinks: { name: string; to: string }[]) => {
  name: string;
  to: string;
  links?: { name: string; to: string }[];
}[] = (expertiseLinks) => [
  { name: "Your Surgeon", to: "/your-surgeon" },
  {
    name: "Expertise",
    to: "/expertise",
    links: [
      ...expertiseLinks,
      { name: "View All Expertise", to: "/expertise" },
    ],
  },
  { name: "Patient Stories", to: "/patient-stories" },
  { name: "Journal", to: "/journal" },
];

const query = graphql`
  query Navbar {
    allDatoCmsExpertise {
      edges {
        node {
          slug
          title
        }
      }
    }
  }
`;

const s = {
  header: ({
    transparent,
    opaque,
    sidebarOpen,
  }: {
    [key: string]: boolean;
  }) => {
    if (!transparent) return tw`bg-white text-liver shadow`;
    if (opaque || sidebarOpen) return tw`bg-liver shadow text-white`;
    return tw`bg-transparent text-white`;
  },
};

const Navbar: React.FC<{ transparent: boolean }> = ({ transparent }) => {
  const {
    allDatoCmsExpertise: { edges: expertiseList },
  } = useStaticQuery(query);
  const expertiseLinks = expertiseList.map(({ node }) => ({
    name: node.title,
    to: `/expertise/${node.slug}`,
  }));
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { scrollY } = useScroll();
  const [opaque, setOpaque] = React.useState(false);
  React.useEffect(() => {
    scrollY.onChange((v) => setOpaque(v > 90));
  }, [scrollY]);
  return (
    <header
      tw="fixed z-20 w-full transition-colors duration-300"
      css={[s.header({ opaque, transparent, sidebarOpen })]}
    >
      <div tw="mx-auto max-w-screen-xl h-auto px-4 py-2">
        <div tw="flex w-full items-center">
          <div tw="grow">
            <Link to="/" onClick={() => setSidebarOpen(false)}>
              {transparent ? (
                <StaticImage
                  src="../images/logo-only-white@120h.png"
                  alt="MitryVision Logo"
                  objectFit="contain"
                  tw="h-14 lg:h-16 inline-flex cursor-pointer [max-width: 220px] xs:[max-width: 275px] lg:[max-width: 312px]"
                  loading="eager"
                />
              ) : (
                <StaticImage
                  src="../images/logo@120h.png"
                  alt="MitryVision Logo"
                  objectFit="contain"
                  tw="h-14 lg:h-16 inline-flex cursor-pointer [max-width: 220px] xs:[max-width: 275px] lg:[max-width: 312px]"
                  loading="eager"
                />
              )}
            </Link>
          </div>
          <ul tw="font-trajan-bold uppercase space-x-6 mr-6 hidden lg:flex">
            {getHeaderNavs(expertiseLinks).map((a) => (
              <li key={a.name}>
                <NavItem {...a} transparent={transparent} />
              </li>
            ))}
          </ul>
          <Link to="/contact-us">
            <button tw="bg-primary hover:bg-primary/90 text-white rounded-lg px-5 py-3 uppercase font-trajan-bold text-xs hidden lg:block transition-colors">
              Contact
            </button>
          </Link>
          <nav tw="lg:hidden">
            <button
              tw="w-10 h-10 relative focus:outline-none"
              onClick={() => setSidebarOpen(!sidebarOpen)}
            >
              <span tw="sr-only">Open main menu</span>
              <div tw="block w-5 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <span
                  aria-hidden="true"
                  tw="block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out"
                  css={[sidebarOpen ? tw`rotate-45` : tw`-translate-y-1.5`]}
                ></span>
                <span
                  aria-hidden="true"
                  tw="block absolute h-0.5 w-5 bg-current transform transition duration-300 ease-in-out"
                  css={[sidebarOpen && tw`opacity-0`]}
                ></span>
                <span
                  aria-hidden="true"
                  tw="block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out"
                  css={[sidebarOpen ? tw`-rotate-45` : tw`translate-y-1.5`]}
                ></span>
              </div>
            </button>
          </nav>
        </div>
        <ClassNames>
          {({ css: _css }) => (
            <Transition
              show={sidebarOpen}
              tw="overflow-hidden"
              enter={_css(tw`transition-[max-height] duration-300 ease-in`)}
              enterFrom={_css(tw`transform max-h-0`)}
              enterTo={_css(tw`transform max-h-screen`)}
              leave={_css(tw`transition-[max-height] duration-150 ease-out`)}
              leaveFrom={_css(tw`transform max-h-screen`)}
              leaveTo={_css(tw`transform max-h-0`)}
            >
              <div tw="w-[fit-content] mx-auto py-12">
                <ul tw="font-trajan-bold uppercase justify-around w-full flex flex-col space-y-6 text-lg text-center">
                  {getHeaderNavs(expertiseLinks).map(({ name, to }) => (
                    <li key={name}>
                      <Link to={to} onClick={() => setSidebarOpen(false)}>
                        {name}
                      </Link>
                    </li>
                  ))}
                  <Link
                    to="/contact-us"
                    onClick={() => setSidebarOpen(false)}
                    tw="w-full"
                  >
                    <button tw="w-full font-trajan-bold uppercase bg-primary hover:bg-primary/90 text-white p-2 rounded-lg">
                      Contact
                    </button>
                  </Link>
                </ul>
              </div>
            </Transition>
          )}
        </ClassNames>
      </div>
    </header>
  );
};

export default Navbar;
