import { FaPhoneAlt } from "@react-icons/all-files/fa/FaPhoneAlt";
import React from "react";
import "twin.macro";
import { useHistoryContext } from "../context/HistoryContext";

const SecretaryBlock = () => {
  const { handleConversion } = useHistoryContext();
  const handlePhoneClick = () => {
    handleConversion("phone", "SecretaryBlock");
  };
  return (
    <div tw="mx-auto mb-14 w-[fit-content] text-center">
      <h3 tw="font-trajan uppercase">Secretary</h3>
      <h4 tw="mb-4 text-lg">Ms. Beth Smith</h4>
      <a
        href="tel:+44 203 8268370"
        tw="flex items-center space-x-2.5 text-lg"
        onClick={handlePhoneClick}
      >
        <FaPhoneAlt />
        <span>0203 8268370</span>
      </a>
    </div>
  );
};
export default SecretaryBlock;
