import React, { useState, useRef } from "react";
import tw from "twin.macro";
import { useClickAway } from "ahooks";
import { Link } from "gatsby";
import { ClassNames } from "@emotion/react";
import { Transition } from "@headlessui/react";

const NavItem: React.FC<{
  transparent: boolean;
  name: string;
  to: string;
  links?: { name: string; to: string }[];
}> = ({ name, to, links, transparent }) => {
  const [open, setOpen] = useState(false);

  const ref = useRef(null);
  useClickAway(() => {
    setOpen(false);
  }, ref);

  return (
    <>
      <div tw="relative">
        <button
          ref={ref}
          onClick={() => setOpen((prev) => !prev)}
          type="button"
          className="group"
          tw="focus:outline-none inline-flex items-center text-base transition-all"
          css={[
            transparent ? tw`hover:text-gray-200` : tw`hover:brightness-150`,
          ]}
        >
          {to && !links?.length ? <Link to={to}>{name}</Link> : name}
          {!!links?.length && (
            <svg
              tw="h-5 ml-2 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </button>
        {!!links?.length && (
          <ClassNames>
            {({ css: _css }) => (
              <Transition
                show={open}
                tw="overflow-hidden absolute max-w-md mt-3 transform w-auto z-10 lg:-translate-x-1/2 lg:left-1/2 lg:ml-0 shadow hidden"
                enter={_css(
                  tw`transform transition ease-out duration-200 block!`
                )}
                enterFrom={_css(tw`opacity-0 translate-y-1`)}
                enterTo={_css(tw`opacity-100 translate-y-0 block!`)}
                leave={_css(
                  tw`transform transition ease-in duration-150 block!`
                )}
                leaveFrom={_css(tw`opacity-100 translate-y-0`)}
                leaveTo={_css(tw`opacity-0 translate-y-1`)}
              >
                <div tw="rounded-lg shadow-lg overflow-hidden">
                  <div tw="relative gap-6 bg-white sm:gap-8 px-8 pb-8 pt-2">
                    {links.slice(0, -1).map(({ to: subTo, name }) => (
                      <Link
                        key={name}
                        to={subTo}
                        tw="ml-4 -my-3"
                        onClick={() => setOpen(false)}
                      >
                        <p
                          css={[
                            tw`text-sm font-medium text-gray-900 hover:text-gray-600 whitespace-nowrap`,
                          ]}
                        >
                          {name}
                        </p>
                      </Link>
                    ))}
                  </div>
                  <div tw="bg-snow sm:gap-8 px-8 py-5 pt-2 -mt-3 space-y-6 sm:space-y-0">
                    <Link
                      to={links[links.length - 1].to}
                      tw="ml-4"
                      onClick={() => setOpen(false)}
                    >
                      <p
                        css={[
                          tw`text-sm font-medium text-gray-900 hover:text-gray-600 whitespace-nowrap`,
                        ]}
                      >
                        {links[links.length - 1].name}
                      </p>
                    </Link>
                  </div>
                </div>
              </Transition>
            )}
          </ClassNames>
        )}
      </div>
    </>
  );
};

export default NavItem;
