import React from "react";
import "twin.macro";
import { graphql, Link, useStaticQuery } from "gatsby";
import SecretaryBlock from "./SecretaryBlock";
import { AiFillYoutube } from "@react-icons/all-files/ai/AiFillYoutube";
import { AiFillLinkedin } from "@react-icons/all-files/ai/AiFillLinkedin";

const query = graphql`
  query Footer {
    allDatoCmsExpertise {
      edges {
        node {
          slug
          title
        }
      }
    }
  }
`;

const getFooterNavs = (expertiseLinks: { name: string; to: string }[]) => [
  {
    header: "Expertise",
    links: [
      ...expertiseLinks,
      { name: "View All Expertise", to: "/expertise" },
    ],
  },
  {
    header: "Consulting Rooms",
    links: [
      { name: "The London Clinic", to: "/consulting-rooms/the-london-clinic" },
      {
        name: "Hospital of St John and St Elizabeth",
        to: "/consulting-rooms/hospital-of-st-john-and-st-elizabeth",
      },
      {
        name: "Lyndhurst Rooms, Royal Free Hospital",
        to: "/consulting-rooms/lyndhurst-rooms-royal-free-hospital",
      },
    ],
  },
  {
    header: "About",
    links: [
      { name: "FAQs", to: "/faq" },
      { name: "Journal", to: "/journal" },
      { name: "Publications", to: "/publications" },
      { name: "Privacy", to: "/privacy" },
      { name: "Terms", to: "/terms-and-conditions" },
    ],
  },
];

const Footer: React.FC = () => {
  const {
    allDatoCmsExpertise: { edges: expertiseList },
  } = useStaticQuery(query);
  const expertiseLinks = expertiseList.map(({ node }) => ({
    name: node.title,
    to: `/expertise/${node.slug}`,
  }));
  return (
    <footer tw="bg-liver py-14 px-10">
      <div tw="max-w-screen-md mx-auto text-white">
        <SecretaryBlock />
        <div tw="flex flex-col space-y-16 md:(flex-row justify-between space-y-0) border-b border-b-gray-50 pb-8">
          {getFooterNavs(expertiseLinks).map(({ header, links }) => (
            <div key={header} tw="space-y-3">
              <h3 tw="text-sm uppercase font-trajan">{header}</h3>
              <ul tw="space-y-2.5">
                {links.map(({ name, to }) => (
                  <li key={name}>
                    <Link to={to} tw="hover:text-gray-200">
                      {name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div tw="flex flex-col-reverse items-center md:(flex-row justify-between) pt-4">
          <div tw="font-original text-xs text-center md:text-left">
            <p tw="text-gray-200 mb-0.5">
              &copy; {new Date().getFullYear()} Mitry Vision. All rights
              reserved.
            </p>
            <p tw="text-gray-200">
              Website by{" "}
              <a
                href="https://omnified.agency"
                target="_blank"
                rel="noopener noreferrer"
                tw="text-white underline"
              >
                Omnified
              </a>
            </p>
          </div>
          <div tw="flex space-x-2.5 text-white text-2xl mb-4 md:mb-0">
            <a
              href="https://www.youtube.com/channel/UCGTiUcU9QJ8pkndN1s67DYg"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Link to Youtube channel"
            >
              <AiFillYoutube />
            </a>
            <a
              href="https://uk.linkedin.com/in/mitryvision"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Link to Linkedin page"
            >
              <AiFillLinkedin />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
